import React, { useState } from "react";
import { Link } from "react-scroll";
import "./Navbar.css";

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-header">
        <Link to="top" smooth={true} duration={500}>
          Rayedon Project Management
        </Link>
      </div>

      <div className="hamburger-menu" onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? "×" : "☰"}
      </div>

      {isMenuOpen && (
        <div className="dropdown-menu slideDown">
          <Link
            to="aboutus-section"
            smooth={true}
            duration={500}
            className="dropdown-link"
            onClick={() => setMenuOpen(false)}
          >
            About Us
          </Link>
          <Link
            to="services-section"
            smooth={true}
            duration={500}
            className="dropdown-link"
            onClick={() => setMenuOpen(false)}
          >
            Services
          </Link>
          <Link
            to="contact-form-section"
            smooth={true}
            duration={500}
            className="dropdown-link"
            onClick={() => setMenuOpen(false)}
          >
            Contact Us
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
