import React from "react";
import "./HeroSection.css";

function HeroSection() {
  return (
    <section className="hero">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <div>RAYEDON PROJECT </div>
        <div>MANAGEMENT SERVICES</div>
      </div>
    </section>
  );
}

export default HeroSection;
