import React from 'react';
import './ContactForm.css'

function ContactForm() {
  return (
    <section className="contact-form-section">
      <h2>Get in Touch</h2>
      <p>We're here to help and answer any questions you might have. Reach out to us, and we'll respond as soon as we can.</p>
      <form>
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email" />
        <input type="text" placeholder="Subject" />
        <textarea placeholder="Message"></textarea>
        <button type="submit">Submit</button>
      </form>
      <div className="direct-contact">
        <p>Phone: +971 58 581 9916</p>
        <p>Email: Info@RDprojectMS.com</p>
      </div>
    </section>
  );
}

export default ContactForm;
