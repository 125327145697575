import "./App.css";
import { useEffect } from "react";
import { Events, scrollSpy } from "react-scroll";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactForm from "./Components/ContactUs/ContactForm";
import Footer from "./Components/Footer/Footer";
import HeroSection from "./Components/HeroSection/HeroSection";
import Navbar from "./Components/Navbar/Navbar";
import Services from "./Components/Services/Services";
import CustomCards from "./Components/CustomCards/CustomCards";

function App() {
  useEffect(() => {
    // Register scroll events for smooth scrolling
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    // Update scrollSpy to track the active section
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <>
      <Navbar />
      <HeroSection id="hero" />
      <AboutUs id="aboutus-section" />
      <CustomCards id="custom-cards" />
      <Services id="services-section" />
      <ContactForm id="contact-form-section" />
      <Footer id="footer" />
    </>
  );
}

export default App;
