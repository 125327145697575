import React from "react";
import "./Services.css";
import { FaLaptop, FaLightbulb, FaShoppingCart } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Services() {
  const servicesData = [
    {
      title: "Information Technology Consulting",
      icon: <FaLaptop />, // Replace with the appropriate icon
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Business Continuity & Disaster Recovery",
        "IT Strategy & Governance",
        "Information Security",
        "IT Infrastructure",
        "Enterprise Architecture and Service",
        "IT Compliance & Standardization",
        "Integrated Banking Solutions",
        "Cash Flow Management Consultation",
        "Procurement Management Consultation",
        "ERP System Management",
      ],
    },
    {
      title: "Business Consulting",
      icon: <FaLightbulb />, // Replace with the appropriate icon
      backgroundColor: "rgb(202,130,22)",
      services: [
        "Business Strategy & Planning",
        "Human Resources Management (HRM)",
        "Project Management (PM)",
        "Business Performance Optimization",
        "Business Process Management (BPM)",
        "Supply Chain Optimization",
      ],
    },
    {
      title: "Interior design",
      icon: <FaShoppingCart />, // Replace with the appropriate icon
      backgroundColor: "rgb(32,171,197)",
      services: [
        "Interior Design Services Interior Design Execution Concept Development",
        "Wooden Furniture Design Bespoke Furniture Exhibition and Stall Design",
        "Exterior Design Furnishing Apartment, Villa or Office Bathroom Concept development",
        "Kitchen Design Artworks and Framing Interior Fit-out Flooring development design",
        "Carpets Renovation and Refurbishment Electrical Fittings Light Fittings",
        "Consulting on Data Comms and Networking Plumbing and Sanitary Fit-out Interior",
      ],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
   
  };

  return (
    <div className="wrapper_main">
      <section className="services-section">
        <h2 className="services-header">Our Services</h2>
        <Slider {...settings}>
          {servicesData.map((section, index) => (
            <div
              key={index}
              className="service-card"
              style={{ backgroundColor: section.backgroundColor }}
            >
              <div className="icon-text-area">
                <div className="service-icon">{section.icon}</div>
                <h3 className="service-title">{section.title}</h3>
              </div>
              <ul className="service-list">
                {section.services.map((service, idx) => (
                  <li key={idx} className="service-item">
                    {service}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
}

export default Services;
