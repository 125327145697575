import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <section className="aboutus-section">
      <h2 className="aboutus-header">About Rayedon Projects</h2>
      <div className="aboutus-content">
        <div className="about_us_main">
          <h3 className="about_us_header">About Us</h3>
          <p className="aboutus-intro">
            Rayedon Projects does more than just provide expert consultation and
            business integration solutions, we help our clients solve their
            business challenges through digital transformation. Raydon Projects
            brings a wide range of digital transformation solutions ,
            procurement, cash and ERP management. We also offer comprehensive
            interior design services for home and office. This business will
            assist those that want to have guidance and council in developing a
            basic design concept of their project, to the person that desires.
            We will design your solutions in close cooperation with your team
            members. To meet your needs and expectations and provide constant
            support.
          </p>
        </div>
        <div className="card our-story-card">
          <h3>Our Story</h3>
          <p>
            Founded in 2023, Rayedon Project Management Services Est. has been a
            leader in IT and management solutions. Our dedication to excellence
            and innovation has marked us as a reliable partner for businesses
            seeking transformative solutions.
          </p>
        </div>

        <div className="card directors-note-card">
          <h3>Director's Note</h3>
          <p>
            “We are collaborators, innovators, and problem solvers. Our journey
            is one of continuous learning and adaptation, always aiming to
            deliver unparalleled value to our clients. Our mission is to empower
            businesses with the tools and strategies they need to thrive in an
            evolving digital landscape.” <br />
          </p>
        </div>

        <div className="card services-card">
          <h3>Our Services</h3>
          <p>
            We provide complete support and counsel from developing a basic
            design concept of a project to executing it in close cooperation
            with your team members, ensuring your needs and expectations are met
            consistently.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
