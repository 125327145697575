import React from 'react';
import './CustomCards.css';
import { FaUserFriends, FaBalanceScale, FaHandshake, FaLightbulb, FaStar } from 'react-icons/fa';

function CustomCards() {
  const cardsData = [
    {
      id: 1,
      title: 'Client Focus',
      description: 'Our clients are our partners, and their interests and needs are our utmost priority. We are intensely focused on providing them with the best solutions for their needs.',
      icon: <FaUserFriends />,
      backgroundColor: 'rgb(202,130,22)',
      backgroundColorCard: 'rgb(32,171,197)'
    },
    {
      id: 2,
      title: 'Integrity & Transparency',
      description: 'We strongly believe that integrity and transparency are key. We communicate honestly and humbly and always seek to do what is fair.',
      icon: <FaBalanceScale />,
      backgroundColor: 'rgb(32,171,197)',
      backgroundColorCard: 'rgb(202,130,22)'
    },
    {
      id: 3,
      title: 'Commitment & Accountability',
      description: 'We are reliable and consistent. Our clients can trust that we are committed to and accountable for delivering their requirements with passion and diligence – ensuring excellent quality.',
      icon: <FaHandshake />,
      backgroundColor: 'rgb(202,130,22)',
      backgroundColorCard: 'rgb(32,171,197)'
    },
    {
      id: 4,
      title: 'Innovation & Change',
      description: 'We stimulate innovation in the way we do business with our clients. We embrace and drive constructive change in our business and for our stakeholders.',
      icon: <FaLightbulb />,
      backgroundColor: 'rgb(32,171,197)',
      backgroundColorCard: 'rgb(202,130,22)'
    },
    {
      id: 5,
      title: 'Excellence',
      description: 'We discover and define, we measure and analyze, we create and optimize, we monitor and control – that is how we achieve the pinnacles of excellence.',
      icon: <FaStar />,
      backgroundColor: 'rgb(202,130,22)',
      backgroundColorCard: 'rgb(32,171,197)'
    }
  ];

  return (
    <div className="custom-cards-container">
      {cardsData.map((card) => (
        <div key={card.id} className="custom-card" style={{ backgroundColor: card.backgroundColor }}>
          <div className="card-icon" style={{backgroundColor: card.backgroundColorCard}}>{card.icon}</div>
          <h3>{card.title}</h3>
          <p>{card.description}</p>
        </div>
      ))}
    </div>
  );
}

export default CustomCards;
